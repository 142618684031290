import React from "react"
import Slider from "react-slick"
import Next from "../images/svgs/next.svg"
import Pre from "../images/svgs/pre.svg"
import Review from "./review"

const Reviews = ({ reviewsArray }) => {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return <Next className={className} style={{ ...style }} onClick={onClick} />
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return <Pre className={className} style={{ ...style }} onClick={onClick} />
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    className: "center",
    centerMode: true,
  }
  return (
    <div className="reviewsSlider">
      <Slider {...settings}>
        {reviewsArray?.map((review, index) => {
          return (
            <Review
              key={index}
              name={review.Reviews.name}
              content={review.Reviews.content}
            />
          )
        })}
      </Slider>
    </div>
  )
}

export default Reviews
