import React from "react"
import { Container } from "@mui/material"
import Qoute from "../images/svgs/doubleQoute.svg"
import ScrollAnimation from "react-animate-on-scroll"

const Review = ({ name, content }) => {
  return (
    <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
      <div className="slideQ">
        <Container>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <Qoute />
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="animate__fadeIn"
            delay={300}
            animateOnce={true}
          >
            <p>{content}</p>
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" delay={500}>
            <span className="author">{name}</span>
          </ScrollAnimation>
        </Container>
      </div>
    </ScrollAnimation>
  )
}

export default Review
